import {Injectable} from '@angular/core';
import {RouterDataResolved} from '@ngxs/router-plugin';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CustomRouteModel} from '../rt-titles/symbols';

export interface RtRouterDataResolverModel {
  url: string;
  params: any;
  queryParams: any;
  data: any;
}

@State<RtRouterDataResolverModel>({
  name: 'routerState',
  defaults: {
    url: '',
    params: {},
    queryParams: {},
    data: {},
  },
})
@Injectable()
export class RtRouterDataResolverState {

  @Selector()
  static url(state: RtRouterDataResolverModel): string {
    return state.url;
  }

  @Selector()
  static params(state: RtRouterDataResolverModel): unknown {
    return state.params;
  }

  @Selector()
  static queryParams(state: RtRouterDataResolverModel): unknown {
    return state.queryParams;
  }

  @Selector()
  static currentRouteData(state: RtRouterDataResolverModel): Record<string, unknown> {
    return state.data;
  }

  @Action(RouterDataResolved)
  resolveRouteData(ctx: StateContext<RtRouterDataResolverModel>, {routerState}: RouterDataResolved<CustomRouteModel>): void {
    ctx.patchState({
      ...routerState,
    });
  }
}
